input[type='date'] {
  background-color: #1f6373;
  padding: 4px 10px;
  font-family: 'Roboto Mono';
  color: #fff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
  background-color: #fff;
  padding: 3px;
  cursor: pointer;
  border-radius: 3px;
}
