.__react_component_tooltip.type-error.show {
  text-shadow: 1px 1px 4px black;
}
.__react_component_tooltip.type-warning.show {
  text-shadow: 1px 1px 4px black;
}
.__react_component_tooltip.type-info.show {
  text-shadow: 1px 1px 4px black;
}

.Toastify__toast-container {
    z-index: 32132132321321321321312 !important;
}
.Toastify__toast--success {
    background: #349a37 !important;
}

.toast-importante {
  background: #642b73 !important; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #642b73,  #9942c6) !important;
  background: linear-gradient(to right, #642b73,  #9942c6) !important;
  color: #fff !important;
}

.toast-mensagem {
  background: #373b44 !important; 
  background: -webkit-linear-gradient(to right, #373b44, #3763a9) !important;  
  background: linear-gradient(to right, #373b44, #3763a9) !important; 
  color: #fff !important;
}

.toast-situacao {
  background: #f16411 !important;
  background: -webkit-linear-gradient(to right, #f5af19, #f16411) !important;
  background: linear-gradient(to right, #f5af19,#f16411) !important;
  color: #fff !important;
}

.toast-situacao div div div.info {
  color: #2d2d2d !important;
}

/* Prepend input  */
.input-group-prepend {
  margin-right: -2px !important;
  z-index: 1;
}

/*.rdt_TableBody {*/
/*  overflow-y: auto;*/
/*}*/

/*.rdt_TableCell a {*/
/*  white-space: normal;*/
/*}*/

.contador-mensagem {
  opacity: 0.7;
  font-size: 16px;
}

.icon-header {
  font-size: 1.8rem;
}

#selecionar-estado {
  font-size: 16px;
}

.busca-por-regiao {
  height: 31px !important;
}

#dataInicial,
#dataFinal,
#input-nome-comprador,
#input-codigo-pregao {
  font-size: 14px;
}

#pesquisa-licitacoes-header,
#select-licitacoes-header {
  height: calc(2.25rem + 2px);
  font-size: 16px;
}

/* TELA MÉDIA */
@media (max-width: 1400px) {
  #selecionar-estado {
    height: 70px;
    font-size: 14px;
  }
}

/* TELA PEQUENA */
@media (max-width: 1150px) {
  .menu-editais-icon {
    font-size: 1.4rem !important;
  }

  #selecionar-estado,
  #search-modal-items-editais {
    font-size: 12px;
  }

  #dataInicial,
  #dataFinal,
  #input-nome-comprador,
  #input-codigo-pregao,
  /*.rdt_TableCol_Sortable,*/
  /*.rdt_TableCol,*/
  /*.rdt_TableCell,*/
  #calendar-icon-datepicker,
  .edital-unitario {
    font-size: 11px;
  }

  /*.rdt_TableRow {*/
  /*  min-height: 50px;*/
  /*}*/

  #pesquisa-licitacoes-header,
  #select-licitacoes-header {
    height: 31px;
    font-size: 13px;
  }

  .contador-mensagem,
  #select-perfil-editais {
    font-size: 13px;
    height: 25px;
  }

  .icon-header {
    font-size: 1.5rem;
  }
}
