#sidebar > ul > li > a:hover {
    color: #4682B4;
    background: #fff;
}

#sidebar > ul > li > a {
    color: white;
}

#sidebar > ul > li > a {
    margin-left: 15px;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;

}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {

}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 12px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 12px;
}

.col-4.text-right {
    line-height: 13px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 13px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 13px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 13px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

#menu-monitora > a {
    margin-bottom: -5px;
}

#mcfg > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#mcfg2 > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#sidebar {
    min-height: 100%
}

#menu-monitora {
}

#mcfg {
}

#monitoraSubmenu {
}

#mcfg2 {
}

#configSubmenu {
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

#menu-monitora > a {
    margin-bottom: -5px;
}

#mcfg > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#mcfg2 > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#sidebar {
    min-height: 100%
}

#menu-monitora {
}

#mcfg {
}

#monitoraSubmenu {
}

#mcfg2 {
}

#configSubmenu {
}

#menu-monitora > a {
    margin-bottom: -5px;
}

#mcfg > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#mcfg2 > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#sidebar {
    min-height: 100%
}

#menu-monitora {
}

#mcfg {
}

#monitoraSubmenu {
}

#mcfg2 {
}

#configSubmenu {
}

a:hover {
    color: black;
}

.slide-out-bottom {
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both
    forwards !important;
  z-index: 2;
}
@keyframes slide-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(1000px);
    opacity: 0;
  }
}

.__react_component_tooltip.type-error.show {
  text-shadow: 1px 1px 4px black;
}
.__react_component_tooltip.type-warning.show {
  text-shadow: 1px 1px 4px black;
}
.__react_component_tooltip.type-info.show {
  text-shadow: 1px 1px 4px black;
}

.Toastify__toast-container {
    z-index: 32132132321321321321312 !important;
}
.Toastify__toast--success {
    background: #349a37 !important;
}

.toast-importante {
  background: #642b73 !important; /* fallback for old browsers */
  background: linear-gradient(to right, #642b73,  #9942c6) !important;
  color: #fff !important;
}

.toast-mensagem {
  background: #373b44 !important;  
  background: linear-gradient(to right, #373b44, #3763a9) !important; 
  color: #fff !important;
}

.toast-situacao {
  background: #f16411 !important;
  background: linear-gradient(to right, #f5af19,#f16411) !important;
  color: #fff !important;
}

.toast-situacao div div div.info {
  color: #2d2d2d !important;
}

/* Prepend input  */
.input-group-prepend {
  margin-right: -2px !important;
  z-index: 1;
}

/*.rdt_TableBody {*/
/*  overflow-y: auto;*/
/*}*/

/*.rdt_TableCell a {*/
/*  white-space: normal;*/
/*}*/

.contador-mensagem {
  opacity: 0.7;
  font-size: 16px;
}

.icon-header {
  font-size: 1.8rem;
}

#selecionar-estado {
  font-size: 16px;
}

.busca-por-regiao {
  height: 31px !important;
}

#dataInicial,
#dataFinal,
#input-nome-comprador,
#input-codigo-pregao {
  font-size: 14px;
}

#pesquisa-licitacoes-header,
#select-licitacoes-header {
  height: calc(2.25rem + 2px);
  font-size: 16px;
}

/* TELA MÉDIA */
@media (max-width: 1400px) {
  #selecionar-estado {
    height: 70px;
    font-size: 14px;
  }
}

/* TELA PEQUENA */
@media (max-width: 1150px) {
  .menu-editais-icon {
    font-size: 1.4rem !important;
  }

  #selecionar-estado,
  #search-modal-items-editais {
    font-size: 12px;
  }

  #dataInicial,
  #dataFinal,
  #input-nome-comprador,
  #input-codigo-pregao,
  /*.rdt_TableCol_Sortable,*/
  /*.rdt_TableCol,*/
  /*.rdt_TableCell,*/
  #calendar-icon-datepicker,
  .edital-unitario {
    font-size: 11px;
  }

  /*.rdt_TableRow {*/
  /*  min-height: 50px;*/
  /*}*/

  #pesquisa-licitacoes-header,
  #select-licitacoes-header {
    height: 31px;
    font-size: 13px;
  }

  .contador-mensagem,
  #select-perfil-editais {
    font-size: 13px;
    height: 25px;
  }

  .icon-header {
    font-size: 1.5rem;
  }
}

input[type='date'] {
  background-color: #1f6373;
  padding: 4px 10px;
  font-family: 'Roboto Mono';
  color: #fff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
  background-color: #fff;
  padding: 3px;
  cursor: pointer;
  border-radius: 3px;
}


