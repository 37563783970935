#sidebar > ul > li > a:hover {
    color: #4682B4;
    background: #fff;
}

#sidebar > ul > li > a {
    color: white;
}

#sidebar > ul > li > a {
    margin-left: 15px;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;

}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {

}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa- {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados- {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 12px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 12px;
}

.col-4.text-right {
    line-height: 13px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 13px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 13px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 13px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

#menu-monitora > a {
    margin-bottom: -5px;
}

#mcfg > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#mcfg2 > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#sidebar {
    min-height: 100%
}

#menu-monitora {
}

#mcfg {
}

#monitoraSubmenu {
}

#mcfg2 {
}

#configSubmenu {
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-7 > h3 {
    margin-top: 8px;
}

.col-6.text-left > a {
    margin-left: 10px;
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-6 > h5 {
    width: 105%
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

#notificacoes {
}

#dados-user {
}

#content {
    background-color: #E8E8E8;
    width: 100%;
}

#sidebarCollapse {
    margin-left: -33px;
    cursor: pointer;
    color: #fff;
    font-size: 20px
}

#icone {
    margin-top: 17px
}

#inlineFormInputGroup {
    font-size: 12px;
    width: 100%;
    height: 48px;
    margin-left: -5px;
}

#brazil-map {
}

#qqcoisa {
    overflow: hidden;
}

.modal-dialog {
}

.modal-content {
}

.modal-header {
}

.modal-title {
}

.fas.fa-bell {
}

.close {
}

.modal-body {
}

.modal-footer {
}

.btn.btn-success {
}

.fas.fa-user {
}

.wrapper {
}

.navbar.navbar-default.topo.animated.fadeInRightBig {
    margin-left: -20px;
    margin-top: -63px;
    min-height: 90px;
    padding-top: 25px;
    width: 105%
}

.col-12 {
    margin-left: -10px;
}

.row {
}

.col-7 {
}

.fas.fa-home {
}

.col-5 {
}

.input-group.col-8. {
}

.input-group-addon {
    height: 48px;
}

.fas.fa-retweet {
}

.col-4.text-center {
}

.col-6.text-right {
}

.fas.fa-bell.text-primary. {
    font-size: 40px;
    margin-left: -15px;
    margin-top: 5px;
    cursor: pointer;
}

.badge.badge-pill.badge-warning. {
    color: #000;;
    font-weight: normal;
    margin-right: -10px;
    cursor: pointer;
}

.col-6.text-left {
}

. {
    margin-left: -10px;
    cursor: pointer;
}

.col-4.animated.fadeInDownBig {
}

.card {
    min-height: 99%;
    max-height: 99%
}

.card-block {
    min-height: 100%;
    max-height: 100%
}

.card-title {
}

.fas.fa-gavel {
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 {
}

.fas.fa-calendar {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

.col-5.text-left > span {
    color: #fff;
    font-size: 15px;
    margin-top: 6px
}

. {
    cursor: pointer;
}

.card.card-primary.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.05s;
}

.card-block {
    padding: 5px
}

.col-12 {
}

.row {
}

.col-2.text-center {
}

.mapa-fonte {
    color: #fff;
}

.col-5.text-left {
}

.col-5.text-center {
}

.btn.btn-sm.btn-outline-info {
    border-color: #fff;
    color: #fff;
    margin-top: 19px;
    font-size: 11px;
}

.card.card-inverse.card-success.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.2s;
}

.card.card-inverse.card-warning.mb-3.text-center.animated.slideInRight {
    animation-delay: 0.35s;
}

.card.card-inverse.card-danger.text-center.animated.slideInRight {
    animation-delay: 0.5s;
}

.col-6 > h5 {
    width: 105%
}

.col-4.animated.fadeInDownBig {
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.row {
}

.col-6 {
}

.fas.fa-calendar {
}

.col-6.text-right {
}

.btn.btn-sm.btn-outline-primary.active. {
    font-size: 9px;
    cursor: pointer;
}

.btn.btn-sm.btn-outline-primary. {
    margin-left: 2px;
    font-size: 9px;
    cursor: pointer;
}

.table.table-hover.table-striped.text-center {
}

.thead-default {
    font-size: 10px
}

.animated.slideInRight {
    font-size: 12px;
    animation-delay: 0.2s;
}

. {
}

.bg-warning {
}

.btn.btn-sm.btn-primary {
    cursor: pointer;
    font-size: 14px;
}

.fas.fa-eye {
}

.bg-success {
}

.bg-info {
}

.bg-primary {
}

.bg-danger {
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
    line-height: 12px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.timeline-heading > p {
    font-size: 15px;
}

.timeline-body > hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.timeline-body > b {
    font-size: 12px;
    font-weight: normal;
}

.col-4.animated.fadeInDownBig {
    overflow: hidden;
}

.card {
    height: 99%
}

.card-block {
    height: 100%
}

.card-title {
}

.fas.fa-clock {
}

.timeline {
    height: 100%
}

.badge.badge-default {
    font-size: 15px;
    font-weight: normal;
}

.timeline-inverted. {
    cursor: pointer;
}

.timeline-badge.bg-warning {
}

.fas.fa-play {
}

.timeline-panel.animated.slideInRight {
    animation-delay: 0.2s;
}

.row {
}

.col-8 {
}

.timeline-title {
    font-size: 15px;
}

.col-4.text-right {
    line-height: 14px
}

.text-muted {
}

.timeline-heading {
    margin-top: -5px
}

.timeline-body {
}

.badge.badge-pill.badge-warning {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-primary {
}

.fas.fa-plus {
}

.badge.badge-pill.badge-primary {
    font-size: 11px;
    font-weight: normal;
}

.timeline-badge.bg-success {
}

.fas.fa-comments {
}

.glyphicon.glyphicon-time {
}

.badge.badge-pill.badge-success {
    font-size: 11px;
    font-weight: normal;
}

#menu-monitora > a {
    margin-bottom: -5px;
}

#mcfg > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#mcfg2 > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#sidebar {
    min-height: 100%
}

#menu-monitora {
}

#mcfg {
}

#monitoraSubmenu {
}

#mcfg2 {
}

#configSubmenu {
}

#menu-monitora > a {
    margin-bottom: -5px;
}

#mcfg > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#mcfg2 > div {
    margin-left: 1px;
    margin-top: -15px;
    margin-bottom: -10px;
}

#sidebar {
    min-height: 100%
}

#menu-monitora {
}

#mcfg {
}

#monitoraSubmenu {
}

#mcfg2 {
}

#configSubmenu {
}

a:hover {
    color: black;
}
